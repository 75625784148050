<div class="legal">
  <H1>Privacy Policy</H1>
  <H5>Introduction</H5>
  <p>Kukai Digital Wallet (“hereinafter Kukai, collectively encompassing {{getHostName()}}) is committed to ensuring the
    privacy of all our users. We take our duty to process your personal data very seriously. This policy represents our
    commitment to your right to privacy, giving you a clear explanation about how we use your information and your
    rights over that information.</p>

  <p>It’s possible to use {{getHostName()}} (“hereinafter website”) without submitting any personal information.</p>

  <H5>Scope of Privacy Policy</H5>
  <p>This privacy policy applies only to your use of our Tezos digital wallet, Kukai. Our website may contain links to
    other websites, which are not hosted by Kukai. Please note that we have no control over how your data is collected,
    stored, or used by other websites and we advise you to check the privacy policies of any such websites before
    providing any data to them.</p>

  <H5>What types of information do we collect and how?</H5>
  <p>Kukai does not keep, process, or store any user data, however some services that Kukai relies on may store some
    user data. Kukai uses Cloudflare services, which collects anonymized website traffic data.</p>

  <p>Data protection laws require us to have a legal justification to process your personal information. Please note,
    that to use Kukai digital wallet, no personal information is needed to use or access our website or to use our
    digital wallet. Once again, Kukai does not store user data on any servers.</p>

  <p>Please note, our website and forum may contain links to other websites which are not hosted by Kukai. Please note
    that we have no control over how your data is collected, stored, or used by other websites and we advise you to
    check the privacy policies of any such websites before providing any data to them.</p>

  <H5>Legal obligation</H5>
  <p>As Kukai does not keep, maintain, or process any personal information, Kukai will attempt to fulfill any legal
    obligations placed upon us, through a lawfully valid legal order by a court of competent jurisdiction.</p>

  <H5>Jurisdiction</H5>
  <p>Our website is under Swedish jurisdiction and is subject to Swedish laws (which would negate the above retention
    rules), and similar. Furthermore, our service providers could also be subject to similar orders without our
    knowledge.</p>

  <p>We take appropriate security measures to ensure that your digital wallet is secured, accurate and up to date.
    However the transmission of information over the Internet is never completely secure, so while we do our best to
    protect any information, however, we cannot guarantee the security of information transmitted to our website.</p>

  <H5>Retention Period For Data</H5>
  <p>We do not hold, store, maintain, or use any personal information as no personal information is needed to use our
    digital wallet.</p>

  <H5>Access to and your rights over your personal information and removal of User data:</H5>
  <p>We do not hold, store, use, or maintain any personal user information. For a User to delete any data from the use
    of our digital wallet please follow the following instructions: </p>

  <p>Log out from the app to remove all user data from the browser cache.</p>

  <p>For more information please see: <a href="https://wallet.kukai.app/fb">https://wallet.kukai.app/fb</a></p>

  <H5>Complaints</H5>
  <p>If you wish to lodge a complaint about our handling of your personal data please get in touch with us on the
    details above with the details of your complaint at contact&#64;kukai.app. We aim to respond to all complaints within 14
    working days. All legal requests will be reviewed by our legal department and appropriate action will be taken. If
    you have a legal request, please contact us at: legal&#64;kukai.app.</p>

</div>